<template>
  <div class="survey-form" v-if="form">
    <h1 class="survey-form__title">{{ form.title }}</h1>
    <p v-if="form.description" class="survey-form__description">
      {{ form.description }}
    </p>
    <iframe class="survey-form__iframe" :src="formUrl"> </iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {},
      formUrl: "",
    };
  },
  methods: {
    getForm() {
      let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
      this.$axios({
        method: "GET",
        url: `/api/survey-${this.$route.params.id}/`,
        params: params,
      })
          .then((response) => {
            this.form = response.data;
            this.formUrl = response.data.iframe + `&id_user=${this.$route.query.id_user}`;
          })
          .catch((error) => {});
    },
  },
  mounted() {
    this.getForm();
  },
};
</script>
<style lang="scss" scoped>
.survey-form {
  width: 1216px;
  margin: 64px auto;
  @media screen and (max-width: 1220px) {
    margin: 20px auto 0;
    padding: 0 76px;
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    padding: 0 16px;
  }
  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    color: #656969;
    margin-top: 20px;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 16px;
    }
  }
  &__iframe {
    margin-top: 40px;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      width: 340px;
      height: 1000px;
    }
  }
}
</style>
